<template>
  <div class="admin-refGoods_order">
    <div class="title">
      <span class="s" style="color: #ee4d2d; border-color: #ee4d2d"
        >退货订单</span
      >
    </div>
    <div class="search-cell">
      <el-form
        size="mini"
        ref="form"
        :inline="true"
        :model="form"
        label-width="80px"
      >
        <el-form-item>
          <el-select
            style="width: 100px; margin-right: 10px"
            size="mini"
            v-model="value"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item"
              :value="index"
            >
            </el-option>
          </el-select>
          <el-input
            :placeholder="options[value]"
            style="width: 120px"
            v-model="keyWord"
          ></el-input>
        </el-form-item>

        <el-form-item label="审核状态">
          <el-select
            style="width: 120px"
            v-model="form.status"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="待审核" value="0"></el-option>
            <el-option label="商家同意" value="1"></el-option>
            <el-option label="商家拒绝" value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="所属学校">
          <el-select
            style="width: 120px"
            v-model="form.school_id"
            placeholder="全部"
            @change="getClassLt"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in schoolList"
              :key="item.id"
              :label="item.school_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属班级">
          <el-select
            style="width: 120px"
            v-model="form.class_id"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in classList"
              :key="item.id"
              :label="item.class_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申请时间：">
          <el-date-picker
            size="mini"
            v-model="form.time"
            type="datetimerange"
            @change="handleTimeChange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="
              page = 1;
              getOrderList();
            "
            >查询</el-button
          >
          <el-button plain @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="tab">
      <el-table
        size="small "
        v-loading="loading"
        border
        :data="tableData.list"
        style="width: 100%; text-algin: center"
        :header-cell-style="{ background: '#F2F2F2', 'border-color': '#000' }"
        :row-style="{ 'border-color': '#000' }"
        :cell-style="{ 'border-color': '#000' }"
        :header-row-style="{ 'border-color': '#000' }"
        @sort-change="handleSort"
      >
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="id"
          label="序号"
          width="80"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="order_no"
          label="订单编号"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="refund_no"
          label="退货编号"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="member_name"
          label="买家名称"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="shop_name"
          label="店铺名称"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="goods_name"
          label="商品名称"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="school_name"
          label="所属学校"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="class_name"
          label="所属班级"
        >
        </el-table-column>
        <el-table-column
          width="140"
          show-overflow-tooltip
          align="center"
          prop="price"
          label="退款金额(RM)"
          sortable="custom"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="number"
          label="退货数量"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="time"
          label="申请时间"
          width="180"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.time | formatTimeS("all") }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="state"
          label="审核状态"
        >
          <template slot-scope="scope">
            <span>{{ orderType[scope.row.state] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="student_code"
          label="操作"
        >
          <template slot-scope="scope">
            <span class="btn" @click="handleLook(scope.row)">查看</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        small
        style="margin-top: 20px"
        background
        layout="prev, pager, next"
        :page-size="page_size"
        :total="tableData.total"
        :current-page="page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getSchoolList, getClassList, refundOrderList2 } from "@/api/admin.js";
export default {
  data() {
    return {
      form: {
        order_no: "",
        refund_no: "",
        buyer_name: "",
        shop_name: "",
        goods_name: "",
        status: "",
        school_id: "",
        class_id: "",
        start_time: "",
        end_time: "",
        time: "",
        order: 0,
      },
      orderType: [
        "待审核",
        "卖家同意",
        "卖家拒绝",
        "申请平台介入",
        "成功退货",
        "退货已关闭",
      ],
      value: 0,
      keyWord: "",
      options: ["订单编号", "退货编号", "买家名称", "店铺名称", "商品名称"],
      schoolList: [],
      classList: [],
      tableData: { list: [] },
      page: 1,
      page_size: 10,
      loading: true,
    };
  },
  created() {
    this.getCampusList();
    this.getClassLt();
    this.getOrderList();
  },
  methods: {
    getOrderList() {
      this.loading = true;
      if (this.value == 0) {
        this.form.order_no = this.keyWord;
      }
      if (this.value == 1) {
        this.form.refund_no = this.keyWord;
      }
      if (this.value == 2) {
        this.form.buyer_name = this.keyWord;
      }
      if (this.value == 3) {
        this.form.shop_name = this.keyWord;
      }
      if (this.value == 4) {
        this.form.goods_name = this.keyWord;
      }
      refundOrderList2({
        ...this.form,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.loading = false;
        if (res.code == 1) {
  
          this.tableData = res.data;
        }
      });
    },
    getCampusList() {
      getSchoolList().then((res) => {
        if (res.code == 1) {
          this.schoolList = res.data;
        }
      });
    },
    getClassLt() {
      this.form.class_id = "";
      getClassList({
        school_id: this.form.school_id,
      }).then((res) => {
        this.classList = res.data;
      });
    },
    handleTimeChange() {
      this.form.start_time = parseInt(this.form.time[0].getTime() / 1000);
      this.form.end_time = parseInt(this.form.time[1].getTime() / 1000);
    },

    handleReset() {
      this.form = {
        order_no: "",
        refund_no: "",
        buyer_name: "",
        shop_name: "",
        goods_name: "",
        status: "",
        school_id: "",
        class_id: "",
        start_time: "",
        end_time: "",
        time: "",
        order: 0,
      };
      this.value = 0;
      this.keyWord = "";
      this.page = 1;
      this.getOrderList();
      this.getClassLt();
    },
    pageChange(index) {
      this.page = index;
      this.getOrderList();
    },
    handleLook(val) {
      sessionStorage.ADMIN_ORDERID = val.id;
      this.$router.options.routes.forEach((item) => {
        if (item.name == "admins") {

          item.children.forEach((val) => {
            if (val.name == "adminRefOrderInfo") {

              val.meta.bread[1].name = "退货订单";
            }
          });
        }
      });
      this.$router.push("adminRefOrderInfo?type=1");
    },
    handleSort(val) {
      if (val.order != null) {
        if (val.order == "ascending") {
          this.form.order = 1;
        }
        if (val.order == "descending") {
          this.form.order = 2;
        }
      } else {
        this.form.order = 0;
      }

      this.getOrderList();
    },
  },
};
</script>
<style lang="less" >
.admin-refGoods_order {
  background: #fff;
  padding: 20px;
  .title {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    .s {
      font-size: 14px;
      border-bottom: 2px solid transparent;
      padding: 0 10px;
      padding-bottom: 10px;
      cursor: pointer;
    }
  }
  .search-cell {
    padding: 20px 0 0;
  }
  .tab {
    .btn {
      font-size: 12px;
      cursor: pointer;
      margin: 0 10px;
    }
    .btn:hover {
      border-bottom: 1px solid #000;
    }
  }
}
.el-form-item__label {
  font-size: 12px;
}
</style>